import { graphql } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import React from 'react'
import get from 'lodash/get'

import Meta from 'components/meta'
import ProductSubTypesPage from 'organisms/ProductSubTypesPage'
import Layout, { PAGE_TRANSITION_DURATION } from 'components/layout'
import Icon from 'components/icon'

export default function Page({ data, pageContext }) {
  const metadata = get(data, 'site.metadata')

  return (
    <Layout
      actionElement={
        <TransitionLink
          className="nav-link pl-0"
          to="/products/"
          style={{ transform: 'scale(1.1)', transformOrigin: 'left' }}
          exit={{
            length: PAGE_TRANSITION_DURATION.fade,
            state: {
              transitionMethod: 'fade',
            },
          }}
          entry={{
            length: PAGE_TRANSITION_DURATION.fade,
            delay: PAGE_TRANSITION_DURATION.fade,
            state: {
              transitionMethod: 'fade',
            },
          }}
        >
          <Icon name="chevron-left" />
          <span>ย้อนกลับ</span>
        </TransitionLink>
      }
    >
      <Meta site={metadata} title="Product" />
      <ProductSubTypesPage
        products={data.products.edges.sort(idSorter)}
        productType={pageContext.productType}
      />
    </Layout>
  )
}

function idSorter(a, b) {
  return a.node.frontmatter.id.localeCompare(b.node.frontmatter.id)
}

export const query = graphql`
  query ProductSubTypesQuery($productType: String) {
    site {
      metadata: siteMetadata {
        title
        description
        siteUrl
        phone
      }
    }
    products: allMarkdownRemark(filter: { frontmatter: { type: { eq: $productType } } }) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 1110) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            id
            name
            type
            subType
            detail
            price
            unit
          }
        }
      }
    }
  }
`
