import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import * as R from 'ramda'
import { navigate } from 'gatsby'

import styles from './style.module.scss'
import { PAGE_TRANSITION_DURATION } from 'components/layout'
import context from 'context'

const entry = {
  length: PAGE_TRANSITION_DURATION.fade,
  delay: PAGE_TRANSITION_DURATION.fade,
  state: {
    transitionMethod: 'fade',
  },
  trigger() {
    const scrollElement = document.querySelector('.tl-edges')
    scrollElement.scrollTo(0, 0)
  },
}

const exit = {
  length: PAGE_TRANSITION_DURATION.fade,
  state: {
    transitionMethod: 'fade',
  },
}

export default function ProductSubTypesPage({ products, productType }) {
  const productSubTypes = R.pipe(
    R.map(R.path(['node', 'frontmatter', 'subType'])),
    R.uniq,
    sortByList(['ประตูสแตนเลสติดไม้', 'ประตูสแตนเลส', 'ประตูเหล็กติดไม้', 'ประตูเหล็ก'])
  )(products)

  // @ts-ignore
  const { selectedProductType, selectedTab, setContext } = useContext(context)
  const defaultProductSubType = productType === selectedProductType ? selectedTab : null

  /** @type {[string, Function]} */
  const [selectedProductSubType, setSelectedProductSubType] = useState(
    defaultProductSubType || productSubTypes[0]
  )

  const selectedTypeProducts = products.filter(
    R.pathEq(['node', 'frontmatter', 'subType'], selectedProductSubType)
  )

  const location = useLocation()
  useEffect(() => {
    console.log('what?')
    const scrollPosition = R.path(['scrollPosition', 'current'], location.state)
    if (scrollPosition) {
      const scrollElement = document.querySelector('.tl-edges')
      scrollElement.scrollTo(0, scrollPosition)
    }
  }, [location.state])

  function handleChange(productSubType) {
    const isSameTab =
      productType === selectedProductType && productSubType === selectedProductSubType
    if (isSameTab) {
      return
    }

    const scrollElement = document.querySelector('.tl-edges')
    scrollElement.scrollTo(0, 0)
    setSelectedProductSubType(productSubType)
    setContext({
      selectedTab: productSubType,
      selectedProductType: productType,
    })
  }

  return (
    <div className="bg-white flex-grow-1">
      <div className={styles.fixedTabs}>
        <Tabs
          className="container"
          productSubTypes={productSubTypes}
          currentTab={selectedProductSubType}
          onChange={handleChange}
        />
      </div>
      <div className="container">
        <Listings products={selectedTypeProducts} tab={selectedProductSubType} />
      </div>
    </div>
  )
}

function sortByList(list) {
  function sorter(a, b) {
    return toOrder(a) - toOrder(b)
  }

  function toOrder(a) {
    const index = list.indexOf(a)
    return index === -1 ? Infinity : index
  }

  return listToSort => {
    return listToSort.sort(sorter)
  }
}

function Tabs({ className, productSubTypes, currentTab, onChange }) {
  return (
    <div className={classNames('ms0 ms1-sm ms2-md', styles.tabs, className)}>
      {productSubTypes.map((productSubType, index) => {
        const isNotFirstElement = index !== 0
        return (
          <React.Fragment key={productSubType}>
            {isNotFirstElement && <Separator />}
            <Tab currentTab={currentTab} name={productSubType} onClick={onChange}>
              {productSubType}
            </Tab>
          </React.Fragment>
        )
      })}
    </div>
  )
}

/**
 * @param {object} props
 * @param {Tab} props.currentTab
 * @param {Tab} props.name
 * @param {function} props.onClick
 * @param {any} props.children
 */
function Tab({ currentTab, name, onClick, children }) {
  function handleClick() {
    onClick(name)
  }

  return (
    <a
      href="#"
      className={classNames(styles.tab, {
        [styles.activeTab]: currentTab === name,
      })}
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

const Separator = styled.div`
  display: inline-block;
  vertical-align: middle;
  border-top: 1px solid black;
  max-width: 100px;
  min-width: 25px;
  width: 100%;
  margin: 0 0.5em;
`

const productsTransitionDuration = '300ms'

/**
 *
 * @param {object} props
 * @param {object} props.products
 * @param {object} props.tab
 * @param {object=} props.className
 * @param {import('react').CSSProperties=} props.style
 */
function Listings({ products, tab, className, style }) {
  return (
    <div className={classNames('position-relative', styles.listings, className)} style={style}>
      <TransitionGroup component={null}>
        <CSSTransition
          key={tab}
          timeout={productsTransitionDuration}
          classNames={styles.transitionedTab}
          style={{
            '--transition-duration': productsTransitionDuration,
          }}
        >
          <div>
            <div className="grid">
              {products.map(({ node: { frontmatter: product } }) => (
                <ProductItem key={product.id} product={product} />
              ))}
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

function ProductItem({ product }) {
  return (
    <div>
      <figure className={styles.productThumbnail}>
        <Img
          fluid={product.image.childImageSharp.fluid}
          alt={`product ${product.id}`}
          className="test"
        />
        <figcaption>
          <Link to={`/products/${product.type}/${product.id}/`} className="click-area" />
          <Link
            to={`/products/${product.type}/${product.id}/`}
            className="inverse position-relative"
          >
            กดเพื่อดูรายละเอียด
          </Link>
        </figcaption>
      </figure>
      <div className="text-primary mt-ms-1">
        <Link to={`/products/${product.type}/${product.id}/`}>{product.name}</Link>
        <span className="float-right">{product.id}</span>
      </div>
      <p className="text-dark mt-ms-1">{product.detail}</p>
    </div>
  )
}

function Link({ to, ...props }) {
  const scrollPositionRef = useRef(0)
  const location = useLocation()
  const triggerTransition = useTriggerTransition({
    to,
    exit,
    entry,
    linkState: { scrollPosition: scrollPositionRef },
  })
  function handleClick(e) {
    e.preventDefault()
    e.persist()

    const scrollElement = document.querySelector('.tl-edges')
    const scrollPosition = scrollElement.scrollTop

    navigate(location.pathname, {
      replace: true,
      state: { scrollPosition: { current: scrollPosition } },
    })
    scrollPositionRef.current = scrollPosition
    setTimeout(() => {
      triggerTransition(e)
    })
  }
  return <a href={to} onClick={handleClick} {...props} />
}
